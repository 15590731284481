<template>
   <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto">
      <div class="clearfix">
         <!-- <button type="button" class="btn btn-secondary float-start">i</button> -->
         <!-- <button type="button" class="btn btn-secondary float-end">Example Button floated right</button> -->
         <p class="text-center fs-3 float-start">
            {{ verbRoot }}<span class="fw-bold">{{ verbEnding }}</span>
            <!-- <i class="fa fa-info-circle" aria-hidden="true"></i> -->
            <!-- <i class="fas fa-info"></i> -->
         </p>
         <button
               type="button"
               class="btn btn-secondary btn-sm float-end"

               v-on:click="stopGame">
            <!-- <i class="fas fa-times"></i> -->
            <i class="fas fa-times-circle"></i>
         </button>
      </div>
      <div class="clearfix">
         <div>
            <div v-bind:key="index"
               v-for="(conjugation, index) in conjugations" >
               <persona v-on:verb-completed="markPersonaAsCompleted"
                  v-bind:conjugation="conjugation"
                  v-bind:index="index"
                  ref="personas"/>
            </div>
         </div>
        <button
               type="button"
               class="btn btn-sm btn-primary float-end mx-auto"
               ref="changeverb"
               v-on:click="loadNextVerb"
               :disabled="disable_next_button"
               autofocus>
            <i class="fas fa-arrow-alt-circle-right"></i>
            <!-- <i class="fas fa-angle-right"></i> -->
         </button>
         </div>
   </div>
</template>

<script>
//https://jsfiddle.net/cihad/2a9wyvbh/

import Persona from './Persona.vue';
export default {
   name:"Conjugation",
   components: {
      Persona
   },
   props: ["conjugations", "infinitive"],
   computed: {
      verbRoot: function () {
         if (this.infinitive === undefined) {
            return "";
         }
         return this.infinitive.slice(0, -2);
      },
      verbEnding: function () {
         if (this.infinitive === undefined) {
            return "";
         }
         return this.infinitive.slice(-2);
      },
   },
   watch: {
      'conjugations': function (new_val, old_val) {
         console.log('value changed from ' + old_val + ' to ' + new_val);
         this.resetForm();
         this.$nextTick(() => this.moveFocus())
      },
   },
   methods: {
      markPersonaAsCompleted(id){
         this.statuses[id].completed = true;
         this.moveFocus();
      },
      moveFocus() {
         var exercise_completed = true;
         var first_uncompleted_item = 0;
         for (var index = 0; index < this.conjugations.length; index++) {
            if (this.statuses[index].completed != true) {
               exercise_completed = false;
               first_uncompleted_item = index;
               console.log("Next focus on: " + first_uncompleted_item + " / " + this.conjugations.length);
               break;
            }
         }
         if (exercise_completed) {
            // Allow uset to go to the next verb
            this.disable_next_button = false;
            console.log("Move focus to next-verb button");
            const this_button = this.$refs.changeverb;
            // https://forum.vuejs.org/t/set-focus-to-input-when-enable-edit/13404
            // because of the timing of Vue’s rendering. Vue buffers changes and essentially renders asynchronously.
            // For this reason call focus in nextTick like so: 
            this.$nextTick(() => this_button.focus())
            return;
         }
         this.$refs.personas[first_uncompleted_item].$refs.myform.focus();
      },
      resetForm() {
         console.log("reset form");
         this.disable_next_button = true;
         for (var mkey in this.statuses) {
            this.statuses[mkey].completed = false;
         }
      },
      loadNextVerb() {
         this.resetForm();
         this.$emit('next-verb');
      },
      stopGame() {
         this.resetForm();
         this.$emit('stop-game');
      }
   },
   data() {
      return {
         "statuses": {
            "0": {"completed": false},
            "1": {"completed": false},
            "2": {"completed": false},
            "3": {"completed": false},
            "4": {"completed": false},
            "5": {"completed": false},
            "6": {"completed": false}
         },
         "disable_next_button": true
      }
   }
}
</script>
