<template>
  <div id="app">
    <Header v-show="!is_game_started"/>
    <main class="container">
      <!--<AddTodo v-on:add-todo="addTodo"/>-->
      <!--<Todos v-bind:todos="todos" v-on:del-todo="deleteTodo"/>-->
      <Conjugation 
        v-bind:conjugations="conjugations[this.tense]"
        v-bind:infinitive="conjugations.Infinitive"
        v-on:next-verb="loadConjugation"
        v-on:stop-game="stopGame"
        v-show="is_game_started" />
      <Commencer 
        v-bind:temps="temps"
        v-on:load-conjugation="loadConjugation"
        v-on:change-tense="changeTense"
        v-show="!is_game_started"/>
        <!-- <h1>Tense: {{tense}}</h1> -->
      <!-- <Section1 v-show="!is_game_started"/>
      <Section2 v-show="!is_game_started"/> -->
      <Footer v-show="!is_game_started"/>
    </main>
  </div>
</template>

<script>
import Header from './components/layout/Header';
// import Section1 from './components/layout/Section1';
// import Section2 from './components/layout/Section2';
import Footer from './components/layout/Footer';
import Conjugation from './components/Conjugation'
import Commencer from './components/Commencer'

import axios from 'axios';

export default {
  name: 'App',
  components: {
    //Todos,
    //AddTodo,
    Header,
    // Section1,
    // Section2,
    Footer,
    Conjugation,
    Commencer
  },
  data() {
    return {
      tense: 'Indicative_present',
      temps: [
         {"id": "Indicative_present",         name: "Indicative - Présent"},
         {"id": "Indicative_futur",           name: "Indicative - Futur"},
         {"id": "Indicative_imparfait",       name: "Indicative - Imparfait"},
         {"id": "Indicative_passeSimple",     name: "Indicative - Passé Simple"},
         {"id": "Perfect_passeCompose",       name: "Perfect - Passé Composé"},
         {"id": "Perfect_futurAnterieur",     name: "Perfect - Futur Antérieur"},
         {"id": "Perfect_plusQueParfait",     name: "Perfect - Plus que parfait"},
         {"id": "Perfect_passeAnterieur",     name: "Perfect - Passé Antérieur"},
         {"id": "Subjunctive_present",        name: "Subjunctive - Présent"},
         {"id": "Subjunctive_imparfait",      name: "Subjunctive - Imparfait"},
         {"id": "Subjunctive_passe",          name: "Subjunctive - Passé"},
         {"id": "Subjunctive_plusQueParfait", name: "Subjunctive - Plus que parfait"},
         {"id": "Conditional_present",        name: "Conditional - Présent"},
         {"id": "Conditional_passe1",         name: "Conditional - Passé 1"},
         {"id": "Conditional_passe2",         name: "Conditional - Passé 2"},
         {"id": "Imperative_present",         name: "Imperative - Présent"},
         {"id": "Participe_passe",            name: "Participe - Passé"},
         {"id": "Participe_present",          name: "Participe - Présent"},
      ],
      is_game_started: false,
      verbs: [],
      conjugations: [],
      statuses: [],
    }
  },
  methods: {
    deleteTodo(id) {
      this.todos = this.todos.filter(todo => todo.id !== id);
    },
    addTodo(newTodo) {
      this.todos = [...this.todos, newTodo];
    },
    loadConjugation() {
      var random_verb_index = Math.floor((Math.random() * this.verbs.length));
      var random_verb = this.verbs[random_verb_index];
      console.log("Index " + random_verb_index + " loaded verb: " + random_verb);
      axios.get(`json/${random_verb}.json`)
        .then(res => this.conjugations = res.data)
        .catch(err => console.log(err));
      this.startGame();
    },
    startGame() {
      this.is_game_started = true;
    },
    stopGame() {
      this.is_game_started = false;
    },
    changeTense(new_tense) {
      // reset fields
      if (new_tense in this.conjugations) {
        for (var i = 0; i < this.conjugations[new_tense].length; i++) {
          //console.log(this.conjugations[i] + " " + this.conjugations[i].Id);
          //Do something
          this.conjugations[new_tense][i].completed = false;
          this.conjugations[new_tense][i].active = false;
          if (i == 0) {
              this.conjugations[new_tense][i].active = true;
          }
        }
      }
      this.tense = new_tense;
    }
  },
  created() {
      axios.get('json/verbs_list_marco.json')
        .then(res => this.verbs = res.data)
        .catch(err => console.log(err));
  }
}
</script>

<style>

.format {
    display: inline-block;
    padding: 0;
    margin: 0 auto;
    line-height: normal;
    overflow: hidden;
    height: 25px;
}
  /*
  http://www.colourlovers.com/palette/65580/traffic_light
  6078A8
  D84830
  F0A800
  78A830
  7890C0
  */
  /* .ok {
    background-color: #78A830;
    border-radius: 3px;
  }
  .warning {
    background-color: #F0A800;
    border-radius: 3px;
  }
  .error {
    background-color: #D84830;
    border-radius: 3px;
  } */

  /* https://www.colourlovers.com/palette/118283/Me_Too_:) */
  .ok {
    background-color: #67B79E;
    border-radius: 3px;
  }
  .warning {
    background-color: #FFB727;
    border-radius: 3px;
  }
  .error {
    background-color: #E4491C;
    border-radius: 3px;
  }

  /* https://www.colourlovers.com/palette/820165/782._202lovers */
  /* .ok {
    background-color: #0A996F;
    border-radius: 3px;
  }
  .warning {
    background-color: #FECD23;
    border-radius: 3px;
  }
  .error {
    background-color: #CF0638;
    border-radius: 3px;
  } */

  /* https://www.colourlovers.com/palette/268637/Figs
  https://www.colourlovers.com/palette/36998/french_roast
  https://www.colourlovers.com/palette/1917934/Same_old...
  https://www.colourlovers.com/palette/848743/(_%E2%80%9D_)
  https://www.colourlovers.com/palette/858593/The_Big_2_Oh_Oh */
</style>