<template>
   <header class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
      <p class="h5 my-0 me-md-auto fw-normal">
         <span class="fw-bold">Fire</span>
         <span class="fas fa-fire"></span>
         <span class="fst-italic">verbs</span>&nbsp;
         <!-- <span class="badge bg-dark">beta</span> -->

      </p>
      <!-- <nav class="my-2 my-md-0 me-md-3">
         <a class="p-2 text-dark" href="#">Features</a>
         <a class="p-2 text-dark" href="#">Enterprise</a>
         <a class="p-2 text-dark" href="#">Support</a>
         <a class="p-2 text-dark" href="#">Pricing</a>
      </nav>
      <a class="btn btn-outline-primary" href="#">Sign up</a> -->
   </header>
</template>

<script>
export default {
   name: "Header"
}
</script>