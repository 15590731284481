var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"format article"},[_vm._v(_vm._s(_vm.trimPronoun()))]),_vm._v(" "),_c('div',{ref:"myform",class:{
         'format': true,
         'colorform': true,
         'is_editable': _vm.is_editable
      },attrs:{"autocapitalize":"off","autocomplete":"off","autocorrect":"off","spellcheck":"false","contenteditable":_vm.is_editable,"tabindex":-1,"solution":((_vm.conjugation.Auxiliary) + " " + (_vm.conjugation.Verb))},on:{"keydown":_vm.onkeypress}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.see_solution_button),expression:"see_solution_button"}],staticClass:" btn btn-sm bg-link fas text-dark",class:{
         'fa-eye': _vm.see_solution,
         'fa-eye-slash': !_vm.see_solution 
         },attrs:{"type":"button"},on:{"click":_vm.showHideSolution}})])}
var staticRenderFns = []

export { render, staticRenderFns }