<template>
   <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
      <br>
      <h1 class="display-4">
         <span class="fw-bold">Fire</span>
         <span class="fas fa-fire"></span>
         <span class="fst-italic">verbs</span>
         </h1>
      <br>
      <p class="lead">Maîtrise les verbes français!</p>
      <br>
      <select 
         class="form-select"
         aria-label="Default select example"
         v-model="selected">
         <option
            v-bind:key="temp.id"
            v-for="temp in temps"
            v-bind:value="temp.id">
            {{ temp.name }}
         </option>
      </select>
      <div class="d-grid gap-2">
         <button
            type="button"
            class="btn btn-success btn-lg btn-block"
            @click="$emit('load-conjugation')" >
            Commencer
         </button>
      </div>
   </div>
</template>

<script>
export default {
   name: "Commencer",
   props: ["temps"],
   data() {
    return {
      selected: 'Indicative_present'
    }
   },
   watch: {
      'selected': function (new_val, old_val) {
         console.log('value changed from ' + old_val + ' to ' + new_val);
         this.$emit('change-tense', new_val);
      },
   },
}

</script>

<style scoped>
</style>
