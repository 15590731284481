<template>
   <div>
      <!-- {{last}} -->
      <div class="format article">{{ trimPronoun() }}</div>
      &nbsp;
      <div
         autocapitalize="off"
         autocomplete="off"
         autocorrect="off"
         spellcheck="false"
         v-bind:contenteditable="is_editable"
         v-bind:class="{
            'format': true,
            'colorform': true,
            'is_editable': is_editable
         }"
         :tabindex="-1"
         :solution="`${conjugation.Auxiliary} ${conjugation.Verb}`"
         ref="myform"
         @keydown='onkeypress'
         ></div>
         &nbsp;
       <div 
         type="button" 
         class=" btn btn-sm bg-link fas text-dark"
         v-show="see_solution_button"
         v-bind:class="{
            'fa-eye': see_solution,
            'fa-eye-slash': !see_solution 
            }"
         v-on:click="showHideSolution">
      </div>
   </div>
</template>

<style scoped>
   .article {
      /* padding-bottom: 7px; */
      padding: 0px;
      border-bottom: 2px;
      font-size: large;
      font-weight: bold;
      height: 25px;
   }

   .text-input {
      font-size: x-large;
      /*vertical-align: middle;*/
      display: inline;
      padding: 0px;
      white-space: pre; /*https://stackoverflow.com/questions/19742946/how-to-make-space-within-span-show-up*/
      border-radius: 3px;
   }
   .colorform {
      font-size: large;
      box-shadow: none;
      border-top: none;
      border-left: none;
      border-right: none;
      padding-left: 1px;
      padding-right: 1px;
      min-width: 90px;
      /*height: 20px;*/
   }
   .is_editable {
      border-bottom: 2px solid #727267;
   }
   .colorform:focus {
      outline: none;
   }
</style>


<script>

export default {
   name:"Persona",
   props: ["conjugation", "index"],
   data() {
      return {
         see_solution: false,
         see_solution_button: false,
         is_editable: true,
         number_of_errors: 0
      }
   },
   watch: {
      'conjugation.Verb': function (new_val, old_val) {
         // the verb selected was changes I reset all the default values
         console.log('value changed from ' + old_val + ' to ' + new_val);

         this.see_solution = false;
         this.see_solution_button = false;
         this.is_editable = true;
         this.number_of_errors = 0;
         //this.getfocusIfActive();
         const editable_form = this.$refs.myform;
         editable_form.innerHTML = '';
      },
   },
   methods: {
      trimPronoun(is_male = true) {
         if (this.conjugation.Pronoun == 'il/elle') {
            if (is_male) {
               return 'il';
            }
            return 'elle';
         } else if (this.conjugation.Pronoun == 'ils/elles')  {
            if (is_male) {
               return 'ils';
            }
            return 'elles';
         }
         return this.conjugation.Pronoun;
      },
      showHideSolution() {
         this.see_solution = !this.see_solution; 
         const editable_form = this.$refs.myform;
         const solution = editable_form.getAttribute("solution").trim().toLowerCase();
         if (this.see_solution) {
            editable_form.innerHTML = solution;
            this.is_editable = false,
            this.see_solution_button = false;
            var grammar_nazi_on = true;
            var is_validated = this.validateEditableForm(editable_form, grammar_nazi_on);
            if (is_validated) {
               console.log("Move to the next persona");
               this.$emit('verb-completed', this.index);
            }
         } else { // TODO not used since the form is no longer editable
            editable_form.innerHTML = "";
         }
      },
      onkeypress(event) {
         // Keep on writing if any key except Enter or tab was pressed.
         //console.log('key ' + event.key + ' (' + event.keyCode + ')');
         //this.last =  'key ' + event.key + ' (' + event.keyCode + ')';

         // Skipping special charachters
         if (['.', ';', ',', '\'', '/', '\\', '[', ']', '`', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)) {
            event.preventDefault();
            return;
         }
         if (! ['Enter', 'Tab'].includes(event.key)) {
            return;
         }

         event.preventDefault();
         console.log("Enter or Tab pressed");
         const editable_form = this.$refs.myform;
         var grammar_nazi_on = true;
         var is_validated = this.validateEditableForm(editable_form, grammar_nazi_on);
         if (is_validated) {
            this.is_editable = false; // Disable the possibility to edit the form again
            this.see_solution_button = false; // because is correct no need to see the show solution button
            console.log("Move to the next verb");
            this.$emit('verb-completed', this.index);
         } else {
            this.number_of_errors += 1;
            if (!this.see_solution_button) {
               if (this.number_of_errors > 1) {
                  this.see_solution_button = true;
               }
            }
            this.keepLastIndex(editable_form);
         }
      },
      validateEditableForm(editable_form, is_warning_enabled = false) {
         //validation of the input...
         const text_inserted = editable_form.innerText.trim().toLowerCase();
         if (text_inserted.length == 0) {
            return false;
         }

         const solution = editable_form.getAttribute("solution").trim().toLowerCase();
         console.log('Text inserted ' + text_inserted + ' solution: ' + solution);

         var new_html = '';

         if (text_inserted === solution) {
            new_html = '<span class="text-input ok">' + text_inserted + '</span>';
            //user_text.html(new_text);
            editable_form.innerHTML = new_html;
            // The answer is correct!
            return true;
         }
         //Maybe some letter are correct but the accent is not let's remore the accents
         //https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
         const clean_text = text_inserted.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
         const clean_solution = solution.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
         var error_found = false;
         var warning_found = false;
         var highlight = 'error';
         var index = null;

         //TODO what if the solution is shorter?
         //Comparing the words letter per letter
         for (var i = 0; i < text_inserted.length; ++i) {
            if (text_inserted.charAt(i) === solution.charAt(i)) {
               // letters are the same go ahead
               continue;
            }

            index = i;
            // There is an inconsistency checking the letters without accents
            if (clean_text.charAt(i) === clean_solution.charAt(i)) {
               warning_found = true;
               highlight = 'warning';
               continue;
            }
            // Letter don't match this is an error
            error_found = true;
            highlight = 'error';
            break;
         }
         console.log("Error: " + error_found);
         console.log("Warning: " + warning_found);

         new_html = '';
         // The error was not found because the text inserted is shoerter than the solution 
         // Appending an error at the end
         if (!error_found && text_inserted.length != solution.length) {
            //end_error = true;
            new_html = text_inserted + '<span class="text-input error">&nbsp;</span>';
            editable_form.innerHTML = new_html;
            return false;
         }

         new_html = '';
         if (error_found || (warning_found && is_warning_enabled)) {
            // Correct part of the string
            new_html += text_inserted.substring(0, index);
            // highlight the error 1 letter
            new_html += '<span class="text-input ' + highlight + '">' + text_inserted.substring(index, ++index) + '</span>';
            // adding the last part of the string as it is
            new_html += text_inserted.substring(index, text_inserted.length);
         } else {
            //The answer is correct apart from the accetn show the correct solution
            new_html = '<span class="text-input ok">' + solution + '</span>';
         }
         editable_form.innerHTML = new_html;
         return false;
      },
      keepLastIndex(obj) {
         // Copied from https://www.programmersought.com/article/97704947769/
         //console.log(obj)
         //console.log(window.getSelection)
         //console.log(document.selection)
         var range = null;
         if (window.getSelection) { //ie11 10 9 ff safari
               obj.focus(); //Solve the problem that ff cannot locate the focus without getting the focus
                           range = window.getSelection(); //Create range
                           range.selectAllChildren(obj); //range selects all child content under obj
                           range.collapseToEnd(); //Move the cursor to the end
         } else if (document.selection) { //ie10 9 8 7 6 5
                           range = document.selection.createRange(); //Create selection object
               //var range = document.body.createTextRange();
                           range.moveToElementText(obj); //range is positioned to obj
                           range.collapse(false); //Move the cursor to the end
               range.select();
         }
      }
   }
}
</script>

